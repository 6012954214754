@import '../../mixin.scss';
.root {
  .title {
    display: flex;
    flex-wrap: wrap;

    .navButtons {
      padding-bottom: 20px;
      margin-left: auto;

      @media screen and (max-width: 400px) {
        margin: 0 auto;
      }
      button {
        @include button-style;

        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        padding: 12px 24px;

        height: 41px;

        gap: 10px;
      }

      .changePopular {
        margin-right: 10px;
      }

      .active {
        background-color: $dark-blue;
      }
    }

    h2 {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
    }
    a {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-decoration: underline;

      color: #0078ff;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .tags {
      position: -webkit-sticky;
      position: sticky;
      top: 0;

      background: radial-gradient(circle, #25223a, #0f0b26);

      padding: 20px;

      width: 250px;
      height: 100%;

      @media screen and (max-width: 1000px) {
        width: 100%;
        position: static;
      }

      h3 {
        background: linear-gradient(93.54deg, #3b82f6 9.03%, #d946ef 43.88%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;

        text-align: center;
      }

      .tagsList {
        @media screen and (max-width: 1000px) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
        }

        p {
          background: linear-gradient(86.95deg, #3b82f6 2.58%, #d946ef 105.04%), #f9fafb;

          text-shadow: 0px 4px 4px #56036ba2;

          font-size: 26px;

          line-height: 24px;

          margin: 15px auto;
          padding: 5px;

          width: 150px;
          text-align: center;
          border-radius: 5px;
        }
      }
    }

    .articles {
      width: 70%;

      @media screen and (max-width: 1000px) {
        width: 100%;
      }
    }
  }
}
