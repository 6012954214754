@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', sans-serif;
  letter-spacing: 0.03em;

  border: none;

  color: #F9FAFB;
  text-shadow: 0px 4px 4px #56036b5b;

}

body {
  margin: 0;
  padding: 0;

  background-color: #1F2937;

  -webkit-box-shadow: 4px 4px 8px 0px rgba(31, 41, 55, 0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(31, 41, 55, 0.2);
  box-shadow: 4px 4px 8px 0px rgba(31, 41, 55, 0.2);


  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}

h1,
h2,
h3,
h4,
h5,
h6{
  font-family: -apple-system, BlinkMacSystemFont, 'Fira Sans Condensed', sans-serif;
}

a{
  text-decoration: none;
}

li{
  list-style: none;
}

.App{
  max-width: 1200px;
  margin: 0 auto;
}


