.footer {
  margin: 160px 0 20px 0;
  padding-top: 40px;

  display: flex;
  justify-content: space-between;

  border-top: 1px solid rgb(139, 139, 139);

  .links {
    width: 382px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    li {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      padding: 15px;

      @media screen and (max-width: 420px) {
        font-size: 14px;
      }
    }
  }

  .copyright {
    width: 330px;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      @media screen and (max-width: 420px) {
        font-size: 12px;
      }
    }

    img {
      @media screen and (max-width: 420px) {
        width: 150px;
      }
    }
  }
}
