@import '../../mixin.scss';
.root {
  background: radial-gradient(circle, #25223a, #0f0b26);

  box-shadow: 4px 4px 8px 0px rgba(53, 73, 51, 0.2);

  padding: 20px;
  margin-bottom: 20px;

  button {
    @include button-style;
    margin-right: 10px;
  }

  .post {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    overflow: hidden;

    .shortText {
      width: 440px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tagsBlock {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    li {
      margin-right: 6px;
      padding: 3px;
      background-color: $grey;
      border-radius: 5px;
    }
  }

  .postInfo {
    display: flex;
    align-items: center;

    p {
      opacity: 0.6;
    }

    label {
      font-weight: 700;
      font-size: 20px;
      margin: auto 5px;
    }

    .viewsCount {
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        margin-right: 6px;
        fill: white;
      }
    }
  }

  .imagePost {
    width: 100%;
    height: 100%;
  }

  .userInfo {
    max-width: 200px;

    display: flex;
    align-items: center;

    img {
      border-radius: 100%;
      width: 50px;
    }
    p {
      padding: 10px;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
